interface Config {
  salesforceUrl: string;
  salesforceClientId: string;
  googleClientId: string;
  serviceUrl: string;
  servicePaginationLimit: number;
  sentryDSN: string | null;
  sentryEnvironment: string | null;
  catUrl: string;
  muiLicenseKey: string | null;

  deployment: {
    version: string;
    timestamp: string;
  };
}

const defaultConfig = {
  servicePaginationLimit: 100,
  // These variables are auto-populated during the CI step.
  muiLicenseKey: "a9c7e10039c55a85cabd7018bafbed5fTz02MTU2NCxFPTE3MDk5MDY5OTUyNjAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
  deployment: {
    version: "a3713caa",
    timestamp: "2024-12-10T11:50:44+01:00",
  },
};

const CONFIGS: { [key: string]: Config } = {
  local: {
    ...defaultConfig,
    salesforceUrl: "https://scandit--partcopy.sandbox.my.salesforce.com/",
    salesforceClientId: "3MVG9sSN_PMn8tjSodyD28jSJpxxEvPYF1XHHnrBTTHVaG7nT1QrCfXiaHiPXFKjj5sZ9rQanepF5ZeWMli1Q", // gitleaks:allow
    googleClientId: "1073585173344-fa4g0rpbsr03drr5jkkhje1m3v8r6dra.apps.googleusercontent.com",
    serviceUrl: "http://localhost:8000",
    sentryDSN: null,
    sentryEnvironment: null,
    catUrl: "http://localhost:3000/sales/",
  },
  test: {
    ...defaultConfig,
    salesforceUrl: "https://scandit--partcopy.sandbox.my.salesforce.com/",
    salesforceClientId: "3MVG9sSN_PMn8tjSodyD28jSJpxxEvPYF1XHHnrBTTHVaG7nT1QrCfXiaHiPXFKjj5sZ9rQanepF5ZeWMli1Q", // gitleaks:allow
    serviceUrl: "https://api.subscriptions.review.scandit.io",
    googleClientId: "1073585173344-lf9ua165h0fotd83lfr6j9knjrs7ie47.apps.googleusercontent.com",
    sentryDSN: "https://de999e3e0f7a45218924788692fd8fa4@sentry.scandit.io/34",
    sentryEnvironment: "test",
    catUrl: "https://ssl.scandit.com/sales/",
  },
  preproduction: {
    ...defaultConfig,
    salesforceUrl: "https://scandit.my.salesforce.com/",
    salesforceClientId: "3MVG9WtWSKUDG.x7zAnPmqPvD3urutWnMioj.N6KxTNDgF09XQTrVE7roMV25NoMmER1kjJUjOCq0OWYTBG4g", // gitleaks:allow
    serviceUrl: "https://api.subscriptions.staging.scandit.io",
    googleClientId: "1073585173344-lf9ua165h0fotd83lfr6j9knjrs7ie47.apps.googleusercontent.com",
    sentryDSN: "https://de999e3e0f7a45218924788692fd8fa4@sentry.scandit.io/34",
    sentryEnvironment: "preproduction",
    catUrl: "https://ssl.scandit.com/sales/",
  },
  production: {
    ...defaultConfig,
    salesforceUrl: "https://scandit.my.salesforce.com/",
    salesforceClientId: "3MVG9WtWSKUDG.x7zAnPmqPvD3urutWnMioj.N6KxTNDgF09XQTrVE7roMV25NoMmER1kjJUjOCq0OWYTBG4g", // gitleaks:allow
    serviceUrl: "https://api.subscriptions.scandit.com",
    googleClientId: "1073585173344-lf9ua165h0fotd83lfr6j9knjrs7ie47.apps.googleusercontent.com",
    sentryDSN: "https://de999e3e0f7a45218924788692fd8fa4@sentry.scandit.io/34",
    sentryEnvironment: "production",
    catUrl: "https://ssl.scandit.com/sales/",
  },
};

export const config = CONFIGS[window.ENVIRONMENT || "local"];
